.infoWindow_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  padding: 15px 20px;
  bottom: 12px;
  left: 12px;
  right: 12px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.infoWindow_title {
  font-size: 14px;
  line-height: 15px;
  color: #111111;
}

.infoWindow_enter {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.infoWindow_enter-text {
  margin-right: 3px;
  font-size: 14px;
  line-height: 15px;
  color: #c7b5aa;
}

.infoWindow_enter-icon {
    width: 7px;
    height: 9px;
  }
