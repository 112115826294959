.search {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
}

.search_surface {
  background-color: #ffffff;
  border-radius: 2px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.search_input-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
  padding: 12px;
}

.search_input {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  line-height: 15px;
  color: #29283a;
}

.search_input-clear {
  width: 12px;
  height: 12px;
  margin-left: 12px;
}

.search_result {
  z-index: 999;
  width: 100%;
  padding: 0px 12px;
  max-height: 288px;
  overflow-y: auto;
}

.search_result-default {
  width: 100%;
  font-size: 14px;
  line-height: 96px;
  text-align: center;
  color: #c7b5aa;
}

.search_result-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 48px;
}

.search_result-item-text {
  font-size: 14px;
  line-height: 15px;
  color: #29283a;
}

.search_result-item-border {
  border-top: 1px solid #f1f1f1;
}
