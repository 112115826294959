.tile_side {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 349px;
}

.tile_side-right {
  right: 0;
}

.tile_side-left {
  left: 0;
}

.tile_side-location {
  margin-top: 41px;
}

.tile_side-icon {
  margin-bottom: 2px;
  width: 51px;
  height: 50px;
  opacity: 1;
}

.tile_side-icon-unselected {
  opacity: 0.5;
}